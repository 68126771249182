import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  StaticImage,
  GatsbyImage,
  withArtDirection,
  getImage,
} from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Numbers from "../components/common/numbers"
import DynLink from "../components/common/dynlink"
import Courses from "../components/common/courses"
import Cta from "../components/common/cta"
import About from "../components/common/about"
import Certifications from "../components/common/certifications"

require("swiper/swiper.scss")
require("swiper/components/navigation/navigation.scss")
require("swiper/components/pagination/pagination.scss")

SwiperCore.use([Navigation, Pagination, Autoplay])

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query Index {
      api {
        slides {
          id
          link
          link_type {
            slug
          }
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH height: 800)
              }
            }
          }
          image_mobile {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH height: 300)
              }
            }
          }
        }
        courses(sort: "position") {
          id
          slug
          title
          acronym
          position
          introduction
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  aspectRatio: 1.8
                )
              }
            }
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <Layout>
        <Seo title="Home" />
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation
          className="main-slider"
        >
          {data.api.slides.map((slide, i) => {
            const images = withArtDirection(
              getImage(slide.image.imageFile.childImageSharp.gatsbyImageData),
              [
                {
                  media: "(max-width: 767px)",
                  image: getImage(
                    slide.image_mobile.imageFile.childImageSharp.gatsbyImageData
                  ),
                },
              ]
            )
            return (
              <SwiperSlide key={i}>
                <DynLink to={slide.link} type={slide.link_type?.slug}>
                  <GatsbyImage image={images} className="banner" alt="" />
                </DynLink>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Container
          className="featured d-none d-md-block"
          style={{ marginTop: -45 }}
        >
          <Row className="align-items-stretch">
            <Col className="flex-grow-1" style={{ zIndex: 2 }}>
              <div className="bg-lighter-blue text-center px-4 py-5 h-100">
                <StaticImage
                  src="../images/home/destaque1.png"
                  alt="Ícone"
                  placeholder="tracedSVG"
                  height={75}
                />
                <h2>Centros de Treinamento</h2>
                <p>
                  de ponta, com infraestrutura completa para a realização de
                  cursos práticos e teóricos.
                </p>
              </div>
            </Col>
            <Col className="flex-grow-1" style={{ zIndex: 2 }}>
              <div className="bg-lighter-blue text-center px-4 py-5 h-100">
                <StaticImage
                  src="../images/home/destaque2.png"
                  alt="Ícone"
                  placeholder="tracedSVG"
                  height={75}
                />
                <h2>Certificação Internacional</h2>
                <p>
                  das mais importantes instituições de saúde do mundo,
                  garantindo qualidade e excelência.
                </p>
              </div>
            </Col>
            <Col className="flex-grow-1" style={{ zIndex: 2 }}>
              <div className="bg-lighter-blue text-center px-4 py-5 h-100">
                <StaticImage
                  src="../images/home/destaque3.png"
                  alt="Ícone"
                  placeholder="tracedSVG"
                  height={75}
                />
                <h2>Profissionais Capacitados</h2>
                <p>
                  e com vasta experiência profissional e teórica ministram
                  nossos cursos, palestras e eventos.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <About />
        <Courses data={data.api.courses} />
        <Cta />
        <Certifications />
        <Numbers />
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage
