import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Container, Row, Col, Card, Badge } from "react-bootstrap"
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

require("swiper/swiper.scss")
require("swiper/components/navigation/navigation.scss")
require("swiper/components/pagination/pagination.scss")

SwiperCore.use([Navigation, Pagination, Autoplay])

const CoursesComponent = ({ data }) => {
  return (
    <div className="bg-grey">
      <Container className="padding-top padding-bottom">
        <Row>
          <Col>
            <div className="title">
              <h1>
                Nossos <span>cursos</span>
              </h1>
              <div className="separator"></div>
            </div>
          </Col>
        </Row>
        <Swiper
          spaceBetween={15}
          slidesPerView={1}
          pagination={{ clickable: true }}
          breakpoints={{ 900: { slidesPerView: 4 } }}
          className="products-slider"
        >
          {data.map((product, i) => {
            return (
              <SwiperSlide key={i}>
                <Link to={`/curso/${product.slug}`}
                 className="no-hover">
                  <Card className="bg-dark-grey h-100">
                    {product.image && (
                      <GatsbyImage
                        image={
                          product.image?.imageFile?.childImageSharp
                            .gatsbyImageData
                        }
                        className="banner"
                        alt=""
                      />
                    )}
                    <Card.Body>
                      <Card.Title>
                        {product.acronym && (
                          <>
                            <Badge variant="primary">{product.acronym}</Badge>{" "}
                          </>
                        )}
                        {product.title}
                      </Card.Title>
                      <div className="separator"></div>
                      <Card.Text className="align-self-center">
                        {product.introduction}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <Link
                        to={`/curso/${product.slug}`}
                        className="btn btn-primary align-self-end"
                      >
                        Saiba mais
                      </Link>
                    </Card.Footer>
                  </Card>
                </Link>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Container>
    </div>
  )
}

export default CoursesComponent
