import React from "react"
import { Link } from "gatsby"

const DynLink = ({
  children,
  to,
  type,
  activeClassName,
  partiallyActive,
  ...other
}) => {

  if (to === "") {
    return children
  }

  if (type === "internal") {
    return (
      <Link
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </Link>
    )
  }
  
  return (
    <a href={to} target="_blank" rel="noreferrer" {...other}>
      {children}
    </a>
  )
}
export default DynLink
