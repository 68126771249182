import * as React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const CtaComponent = () => {
  return (
    <Container fluid className="bg-dark-grey cta">
      <Row className="align-items-center">
        <Col md={6} className="p-0 pr-2 cta-image">
          <StaticImage
            src="../../images/common/cursos.png"
            alt="Thumb"
            placeholder="blurred"
            layout="fullWidth"
          />
        </Col>
        <Col md={5} className="py-4 py-md-0">
          <div className="title">
            <h1>Cursos personalizados</h1>
          </div>
          <p>
            O CTEMER prepara cursos com grade curricular específica às
            necessidades da sua equipe.
          </p>
          <Link to="/contato?ref=cta" className="btn btn-primary">
            Saiba mais
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default CtaComponent
